<template>
  <base-section id="theme-features">
    <base-section-heading :title="$ml.get('advantages')" class="mx-2">
      {{ $ml.get("advantages_text") }}
    </base-section-heading>
    <v-container>
      <v-row>
        <v-col  cols="12" md="6">
          <base-avatar-card icon="mdi-shield-half-full" :title="$ml.get('reliability')" align="left" horizontal>
            {{ $ml.get('reliability_text') }}
          </base-avatar-card>
        </v-col>
                <v-col  cols="12" md="6">
          <base-avatar-card icon="mdi-fingerprint" :title="$ml.get('privacy')" align="left" horizontal>
            {{ $ml.get('privacy_text') }}
          </base-avatar-card>
        </v-col>
                <v-col  cols="12" md="6">
          <base-avatar-card icon="mdi-chart-areaspline" :title="$ml.get('stability')" align="left" horizontal>
            {{ $ml.get('stability_text') }}
          </base-avatar-card>
        </v-col>
                <v-col  cols="12" md="6">
          <base-avatar-card icon="mdi-professional-hexagon" :title="$ml.get('professionalism')" dark color="primary" align="left" horizontal>
            {{ $ml.get('professionalism_text') }}
          </base-avatar-card>
        </v-col>
                <v-col  cols="12" md="6">
          <base-avatar-card icon="mdi-silverware-variant" :title="$ml.get('convenience')" align="left" horizontal>
            {{ $ml.get('convenience_text') }}
          </base-avatar-card>
        </v-col>
                <v-col  cols="12" md="6">
          <base-avatar-card icon="mdi-whatsapp" :title="$ml.get('support')" align="left" horizontal>
            {{ $ml.get('support_text') }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionThemeFeatures",
  data: () => ({
   
  }),
};
</script>
